import type { AnalyticEvent, Availabilities, PaymentPlan, SingleProject } from '@/shared/types';
import type { Amenity } from '@/config/filters';
import { ProjectIdRouteQueryKey } from '@/composables/map/use-project-fly/constants';

export function useSingleProject() {
  const eventBus = useEventBus();
  const projectId = useState('projectId', () => shallowRef<number | null>(null));
  const route = useRoute();
  const { $api, $analytics } = useNuxtApp();

  async function fetchSingleProject(slug: string) {
    const data: SingleProject = await $api(`/projects/${slug}`);
    projectId.value = data.id;

    const projectAnalyticsEventKey = getProjectAnalyticsEventKey();
    projectAnalytics(projectAnalyticsEventKey, data);
    return data;
  }

  async function fetchProjectAmenities(projectId: number) {
    const data: Amenity[] = await $api(`/amenities`, {
      params: { projectId },
    });

    return data;
  }

  async function fetchProjectAvailability(projectId: number, pageSize: number) {
    const data: Availabilities = await $api(`/listings`, {
      params: { projectId, pageSize },
    });

    return data;
  }

  async function fetchProjectPaymentPlan(projectId: number) {
    const data: PaymentPlan = await $api(`projects/${projectId}/payment-plan`);

    return data;
  }

  async function mapOverviewOnShowOnMap(project_id: number, isMapViewLayout: boolean) {
    if (isMapViewLayout) {
      eventBus.emit('fly_to_project_from_modal_page', {
        project_id,
      });
    } else {
      await navigateTo({
        name: 'interactive-map',
        query: {
          [ProjectIdRouteQueryKey]: project_id,
        },
      });
    }
  }

  function showMoreProjectDescription(condition_of_description: boolean, project_id: number, project_slug: string) {
    $analytics.trackEvent('click_show_more_project_description', 'click', {
      project_id, // ID проекта (project)
      project_slug, // slug (название) проекта (project)
      condition_of_description: condition_of_description ? 'show' : 'hide', // show | hide
    });
  }

  function getProjectAnalyticsEventKey(): AnalyticEvent {
    const historyBack = window?.history.state.back as string | undefined;
    const matchedBack = route.matched[0].path;

    const isViewFromMap = matchedBack.includes('interactive-map');
    const isOpenedFromMap = historyBack?.includes('interactive-map');

    switch (true) {
      case isViewFromMap:
        return 'view_project_details_on_map';
      case isOpenedFromMap:
        return 'go_on_project_page_from_overlay';
      default:
        return 'view_project_page';
    }
  }

  function projectAnalytics(event: AnalyticEvent, project?: SingleProject): void {
    $analytics.trackEvent(event, 'view', {
      project_id: project?.id, // ID проекта (project)
      project_slug: project?.slug, // slug (название) проекта (project)
    });
  }

  return {
    projectId,
    fetchSingleProject,
    fetchProjectAvailability,
    fetchProjectAmenities,
    fetchProjectPaymentPlan,
    mapOverviewOnShowOnMap,
    projectAnalytics,
    showMoreProjectDescription,
  };
}
